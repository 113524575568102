import '../css/styles.scss'

import { OffCanvas } from '../lib/offcanvas/js/offcanvas'
import autosize from 'autosize'

// Off-canvas
window.offCanvas = new OffCanvas({
  debug: false,
  overlay: true,
})

var honeypots = document.querySelectorAll('.nui-field--confirmSend2 input')

if (honeypots.length) {
  honeypots.forEach(function (field) {
    field.setAttribute('checked', 'checked')
  })
}

autosize(document.querySelector('textarea'));
